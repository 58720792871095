import React, { useEffect } from "react"
import { Link } from "gatsby"
import LayoutComponent from "../components/LayoutComponent"
import { Seo } from "../components/seo"
export interface NotFoundPageProps {}

const NotFoundPage: React.SFC<NotFoundPageProps> = (props: {
  path: any
  data: any
}) => {
  useEffect(() => {
    if (props.path !== "/") {
      const HEADER = document.querySelector(".content-header")
      const HEADER_MOBIL = document.querySelector(".header-mobile")
      HEADER?.classList.add("swap-header")
      HEADER_MOBIL?.classList.add("change-backgorund")
    }
  }, [])
  return (
    <>
      <Seo
        title="Super Sonic Design INC | 404"
        url="https://supersonicdesign.com/404"
        description="404 NOT FOUND"
      />
      <LayoutComponent>
        <div className="container container-404">
          <div className="row">
            <div className="col-12">
              <h4>404</h4>
              <span>NOT FOUND</span>
              <p>Oops! Something is wrong.</p>
              <Link to="/">Go back to home, it's better</Link>
            </div>
          </div>
        </div>
      </LayoutComponent>
    </>
  )
}

export default NotFoundPage
